import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Icon from "../../../../../common/Icon";
import image from "../../../../../../assets/images/Phone.png";
import AiItem from "../AiItem";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../../../common/Loader";
import video from 'assets/videos/AI Template Tutorial.mp4'
import QuillEditor from "components/private/sections/NewDocumentEditor/QuillEditor";

const EditorRight = ({ content, title, handleReset, isLoading }) => {
  const [isHidenVideo, setIsHidenVide] = useState(true);
  return (
    <div className="editor__right">
      {isLoading && <Loader />}
      <div className="editor__right__wrapper">
        <div className="editor__right__top">
          <div className="title">Generated Content</div>
          {!!content.length && (
            <Icon onClick={() => handleReset()} name="Refresh" />
          )}
        </div>
        {!!content.length ? (
            <div className="editor__right__quilleditor">
              <QuillEditor
                text={ content }
                onChange={ () => {} }
                placeholder="Begin writing on your own..."
              />
          </div>
          // content.map((item) => {
          //   return <AiItem key={uuidv4()} text={item.text} title={title} />;
          // })
        ) : (
          <div className="editor__right__video">
            <div className="text1">
              Fill out the form and start generate your content
            </div>
            <div className="text2">Watch video to see how it works</div>
            <img src={image} alt="" />
            <button
              className="btn-second"
              onClick={() => setIsHidenVide(false)}
            >
              Watch Video
            </button>
          </div>
        )}

        {!isHidenVideo && (
          <div className="videoPlayer">
            <div
              className="closer"
              role="presentation"
              onClick={() => setIsHidenVide(true)}
            ></div>
            {/* <iframe
              width="90%"
              height="90%"
              src="https://www.youtube.com/embed/LjeHXjrJ8XE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe> */}
            <div className="videoPlayer__video">
              <video src={video} controls autoPlay></video>
              <Icon name="Close" onClick={() => setIsHidenVide(true)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

EditorRight.propTypes = {
  title: PropTypes.string,
  handleReset: PropTypes.func,
  content: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default EditorRight;
