const davinci = "davinci-instruct-beta-v3";
const curie = "curie-instruct-beta";
export const tools = [
  {
    id: 1,
    icon: "WebSite",
    description: "Rewrite your sentence in a different way.",
    title: "Paraphrase The Text",
    prompt: "Given the text, rephrase the text. Text:",
    token: 600,
    engine: davinci,
  },
  {
    id: 2,
    icon: "WebSite",
    description: "Expand the sentence in a more creative way.",
    title: "Expand The Text",
    prompt: "Given the content, Write the continuation of the content as a complete tempalate:",
    token: 600,
    engine: davinci,
  },
  {
    id: 3,
    icon: "WebSite",
    description: "Write a creative story to hook your audience.",
    title: "Engaging Story",
    prompt: "Given the story context, Write an engaging story as a complete template. Story context:",
    token: 600,
    engine: curie,
  },
  {
    id: 41,
    icon: "Blog",
    description: "Write an attention grabbing title for your blog article.",
    title: "Blog Post Title",
    prompt: "Given the description, write a title for an article. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 4,
    icon: "Blog",
    description: "Write your blog outline to create visual map.",
    title: "Blog Post Outline",
    prompt: "Given the context, Create an complte outline for a blog article. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 5,
    icon: "Blog",
    description: "Get introduction ideas to get started with your blog.",
    title: "Blog Post Intro",
    prompt: "Given the description, Write an introduction paragraph for a blog article. Description:",
    token: 600,
    engine: curie,
  },
  {
    id: 6,
    icon: "Blog",
    description: "Write a paragraph for your blog post.",
    title: "Blog Post Paragraph",
    prompt: "Given the description, write a blog post complete response as a style template. Write the following text with each sentence on a new line. Insert a new line after each period. Description:",
    token: 600,
    engine: curie,
  },
  {
    id: 7,
    icon: "Blog",
    description: "Summarize your blog with a great conclusion.",
    title: "Blog Conclusion",
    prompt: "Given the context, write a conclusion template for a blog article. Context: ",
    token: 600,
    engine: curie,
  },
  {
    id: 8,
    icon: "Blog",
    description: "Generate blog ideas with a push of a button.",
    title: "Generate Blog Ideas",
    prompt: "Given the context, brainstorm blog article ideas. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 9,
    icon: "Idea",
    description: "Brainstorm Product Ideas for your business.",
    title: "Generate Product Ideas",
    prompt: "Given the product description, brainstorm product ideas, one per line. Product description: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 10,
    icon: "Idea",
    description: "Brainstorm marketing ideas for your business.",
    title: "Generate Marketing Ideas",
    prompt: "Given the product description, brainstorm marketing ideas for the product, one per line. Product description: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 11,
    icon: "Idea",
    description: "Generate ideas about anything.",
    title: "Brainstorm Ideas",
    prompt: "Given the context, make a list of ideas, one per line. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 12,
    icon: "Social",
    description: "Create your amazing Facebook Ad Copy.",
    title: "Facebook Ad Copy",
    prompt: "Given the product description, write an advertisement complete template for the product to run on Facebook. Product Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 14,
    icon: "Social",
    description: "Create your amazing LinkedIn Ad copy.",
    title: "Linkedin Ad Copy",
    prompt: "Given the product description, write an advertisement complete template for the product to run on Linkedin. Product Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 15,
    icon: "Social",
    description: "Create your amazing Google Ad Copy.",
    title: "Google Ad Copy",
    prompt: "Given the product description, write an advertisement complete template the product to run on Google. Product Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 16,
    icon: "Email",
    description: "Write a welcome email to your customers.",
    title: "Welcome Email",
    prompt: "Given the description, write a complete welcome email to a customer. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 17,
    icon: "Email",
    description: "Write a thank your email to your customers",
    title: "Thank You Email",
    prompt: "Given the description, write a complete thank you email to a customer for the purchase of product. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 18,
    icon: "Email",
    description: "Write a personalized email for your customers",
    title: "General Email",
    prompt: "Given the description, write an email to a customer. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 19,
    icon: "Email",
    description: "Write a Personalized email subject line",
    title: "Email Subject Line",
    prompt: "Given the description, write a complete email subject line. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 20,
    icon: "Email",
    description: "Write a personalized sales email to your prospects.",
    title: "Personal Cold Email",
    prompt: "Given the product description, write a complete personalized cold email to a prospect. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 21,
    icon: "Framework",
    description: "Write a sales copy using AIDA framework.",
    title: "AIDA Framework",
    prompt: "Given the product description, write a complete AIDA sales copy for the product as a template. Product description: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 22,
    icon: "Framework",
    description: "Write a sales copy using PAS framework.",
    title: "Pain Agitate Solution Framework",
    prompt: "Given the product description, write a complete sales copy using copywriting framework pain agitate solution for the product as a template. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 23,
    icon: "Framework",
    description: "Write a sales copy using FAB framework",
    title: "Feature Advantage Benefit Framework",
    prompt: "Given the product description, write a complete sales copy using copywriting framework feature Advantage benefit for the product as a template. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 24,
    icon: "Framework",
    description: "Write a sales copy using BAB framework",
    title: "Before-After-Bridge Framework",
    prompt: "Given the product description, write a complete copy using copywriting framework Before-After-Bridge for the product as a template. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 25,
    icon: "EcomProduct",
    description: "Write an ecommerce product title.",
    title: "Ecom Product Title",
    prompt: "Given the product description, write a title for the product. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 26,
    icon: "EcomProduct",
    description: "Write an ecommerce product description",
    title: "Ecom Product Description",
    prompt: "Given the product description, write a complete description for the product as a template. Product description: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 27,
    icon: "EcomProduct",
    description: "Write an ecommerce product features",
    title: "Ecom Product Features",
    prompt: "Given the product description, make a list of features for the product, one per line. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 28,
    icon: "Bio",
    description: "Write a unique and eye catching value prop for your company/product.",
    title: "Value Proposition",
    prompt: "Given the product description, write a complete unique value proposition for the product. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 29,
    icon: "Bio",
    description: "Write a bio for your company.",
    title: "Company Bio",
    prompt: "Given the company description, write a complete bio for the company as a template. Company description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 30,
    icon: "Personal",
    description: "Write a personal bio.",
    title: "Personal Bio",
    prompt: "Given the description, write a cmplete personal bio for the person as a template. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 31,
    icon: "Video",
    description: "Generate video topic ideas",
    title: "Video Topic Ideas",
    prompt: "Given the description, make a list of video topic ideas, one per line. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 32,
    icon: "Video",
    description: "Create a video script outline for your videos.",
    title: "Video Script Outline",
    prompt: "Given the context, Create a complete outline for a video. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 33,
    icon: "Video",
    description: "Generate a unique hook for your video opener.",
    title: "Video Persuasive Hook",
    prompt: "Given the video description, Write a complete persuasive opening hook for the video. Video description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 34,
    icon: "Adds",
    description: "Generate a Facebook Post Caption.",
    title: "Facebook Post Caption",
    prompt: "Given the product description, Write a complete facebook post caption for the product. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 36,
    icon: "Adds",
    description: "Generate a LinkedIn Post Caption.",
    title: "LinkedIn Post Caption",
    prompt: "Given the product description, Write a complete Linkedin post caption for the product. Product description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 37,
    icon: "WebSite",
    description: "Generate a Meta title for your Website",
    title: "Website Meta Title",
    prompt: "Given the info, write a meta title for a website. Info:",
    token: 600,
    engine: davinci,
  },
  {
    id: 38,
    icon: "WebSite",
    description: "Generate a Meta description for your Website",
    title: "Website Meta Description",
    prompt: "Given the info, write a  complete meta description for a website. Info:",
    token: 600,
    engine: davinci,
  },
];

export const dashboardTools = [
  {
    id: 1,
    icon: "WebSite",
    description: "Rewrite your sentence in a different way.",
    title: "Paraphrase The Text",
    prompt: "Given the text, rephrase the text. Text:",
    token: 600,
    engine: davinci,
  },
  {
    id: 2,
    icon: "WebSite",
    description: "Expand the sentence in a more creative way.",
    title: "Expand The Text",
    prompt: "Given the content, Write the continuation of the content:",
    token: 600,
    engine: davinci,
  },
  {
    id: 41,
    icon: "Blog",
    description: "Write an attention grabbing title for your blog article.",
    title: "Blog Post Title",
    prompt: "Given the description, write a complete title for an article as a template. Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 12,
    icon: "Social",
    description: "Create your amazing Facebook Ad Copy.",
    title: "Facebook Ad Copy",
    prompt: "Given the product description, write a complete advertisement for the product as a template to run on Facebook. Product Description:",
    token: 600,
    engine: davinci,
  },
  {
    id: 7,
    icon: "Blog",
    description: "Summarize your blog with a great conclusion.",
    title: "Blog Conclusion",
    prompt: "Given the context, write a complete conclusion paragraph for a blog article as a template. Context: ",
    token: 600,
    engine: curie,
  },
  {
    id: 4,
    icon: "Blog",
    description: "Write your blog outline to create visual map.",
    title: "Blog Post Outline",
    prompt: "Given the context, Create a complete outline for a blog article as a template. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 11,
    icon: "Idea",
    description: "Generate ideas about anything.",
    title: "Brainstorm Ideas",
    prompt: "Given the context, make a list of ideas, one per line. Context: ",
    token: 600,
    engine: davinci,
  },
  {
    id: 6,
    icon: "Blog",
    description: "Write a paragraph for your blog post.",
    title: "Blog Post Paragraph",
    prompt: "Given the description, write a compelete paragraph for a blog article. Description: ",
    token: 600,
    engine: curie,
  },
];
