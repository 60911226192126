/* eslint-disable no-unreachable */
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SIGNUP_START,
  UPDATE_USER,
  UPDATE_MEMBER,
  INVITED__USER__LOADED,
  USER_LOAD_START,
  USER_LOAD_END
} from "../actions/types";
import Cookies from 'js-cookie';

const initialState = {
  token: localStorage.getItem("token") || Cookies.get('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  invitedUser: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case INVITED__USER__LOADED:
      return {
        ...state,
        invitedUser: payload
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.data,
        ...payload.other,
      };

      break;
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      Cookies.set('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
      break;
    case SIGNUP_FAIL:
      localStorage.removeItem("token");
      Cookies.remove('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
      break;
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      Cookies.remove('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
      break;
    case USER_LOAD_START:
      return {
        ...state,
        isLoading: true,
      }
      break;
    case USER_LOAD_END:
      return {
        ...state,
        isLoading: false,
      }
      break;
    case UPDATE_USER:
      localStorage.setItem("token", payload.token);
      Cookies.set('token', payload.token);
      return {
        ...state,
        user: { ...action.payload.user },
        toke: action.payload.token,
        isAuthenticated: true,
        loading: false,
      };
      break;
    case UPDATE_MEMBER:
      localStorage.setItem("token", payload.token);
      Cookies.set('token', payload.token);
      return {
        ...state,
        toke: action.payload.token,
        isAuthenticated: true,
        loading: false,
        invitedUser: { ...action.payload.user },
        user: {
          ...state.user,
          name: action.payload.user.name,
          language: action.payload.user.name,
        }
      }
    case SIGNUP_START:
      return {
        loading: true,
      }
    default:
      return state;
  }
}
